<script>
import {defineComponent} from 'vue'
import DropZone from "@/components/widgets/dropZone.vue";
import Swal from "sweetalert2";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import SelectTerminal from "@/components/custom/SelectTerminal.vue";

export default defineComponent({
  name: "excel_tab",
  components: {
    DropZone,
    SelectTerminal
  },
  data() {
    return {
      files: [],
      train_name: '',
      terminal: null,
      train_name_options: [],
      step: 'step1',
      loading_step1: false,
      loading_step2: false,
      train_parsed_data: []
    }
  },
  methods: {
    async onDropZoneChange() {
      let file = document.querySelector(".dropzoneFile").files[0]
      if (this.isExcelFile(file)) {
        this.files = []
        this.files.push(file)
        let sheet_names = await readXlsxFile(this.files[0], {getSheets: true})
        this.train_name_options = sheet_names.map(sheet => ({
          name: sheet.name
        }))
      }
    },
    async onFileDrop(e) {
      let file = e.dataTransfer.files[0];
      if (this.isExcelFile(file)) {
        this.files = []
        this.files.push(file)
      }
    },
    deleteFile() {
      document.querySelector(".dropzoneFile").value = ""
      this.files = []
    },
    isExcelFile(file) {
      let is_valid = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      if (is_valid === false) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        Toast.fire({
          icon: 'warning',
          title: 'Only excel files are allowed',
        })
        return false
      } else {
        return true
      }
    },

    async uploadExcel() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (this.train_name.trim().length <= 0) {
        await Toast.fire({
          icon: 'warning',
          title: 'Please enter train name',
        })
      } else {
        this.loading_step1 = true
        const formData = new FormData();
        formData.append('file', this.files[0]);
        try {
          let response = await axios({
            url: `/train/parse-xlsx/${this.terminal.value}/${this.train_name}/`,
            method: 'post',
            data: formData,
            headers: {"Content-Type": "multipart/form-data"},
          })
          this.train_parsed_data = response.data
          this.step = 'step2'
        } catch {
          await Toast.fire({
            icon: 'warning',
            title: 'Something went wrong, please check your file and train name',
          })
        }
        this.loading_step1 = false
      }
    },

    async confirmExcel() {
      this.loading_step2 = true
      let not_deleted_train_data = this.train_parsed_data.filter(item => item.is_deleted !== true)
      let response = await axios.post(`/train/process-xlsx/${this.train_slug}/`, not_deleted_train_data)
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (response.status === 200) {
        this.loading_step2 = false
        this.deleteFile()
        this.train_name = ''
        this.train_name_options = []
        this.step = 'step1'
        this.loading_step1 = false
        this.train_parsed_data = []
        await Toast.fire({
          icon: 'success',
          title: 'Excel successfully uploaded',
        })
      } else {
        this.loading_step2 = false
        await Toast.fire({
          icon: 'warning',
          title: 'Something went wrong, please try again',
        })
      }
    },

    async downloadExcelTemplate() {
      try {
        const response = await axios.get('/train/generate-xlsx/V/', {
          responseType: 'blob' // Set the response type to 'blob'
        });

        const fileData = response.data;
        const blob = new Blob([fileData], {type: 'application/vnd.ms-excel'});

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'train_template.xlsx';

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      }
    }
  },
  computed: {
    train_slug() {
      return this.$route.params.train_slug
    },
    canUploadExcelProcess() {
      return this.train_name.trim().length > 0 && this.terminal !== null
    },
    train_id() {
      try {
        return JSON.parse(localStorage.getItem('train'))['id']
      } catch {
        return null
      }
    }
  }
})

</script>

<template>
  <div class="tab-pane fade"
       :class="{'show active': $route.name === 'train_details_wagon_upload'}"
       id="train-upload-excel" role="tabpanel">
    <b-card no-body>
      <b-card-body v-show="step === 'step1'">
        <div class="mb-3">
          <h5 class="text-muted cursor-pointer d-inline-block" @click="downloadExcelTemplate()">
            <i class="bx bxs-download me-1 align-bottom text-dark c_icon_hoverable"></i>
            Download Excel Template
          </h5>
        </div>
        <DropZone @change="onDropZoneChange" @drop="onFileDrop"/>
        <ul class="list-unstyled mb-0 mt-3 mb-3" v-if="files.length > 0">

          <li>
            <div class="border rounded" v-for="(file, index) of files" :key="index">
              <div class="d-flex p-2 align-items-center">
                <div class="flex-grow-1">
                  <div class="pt-1">
                    <h5 class="fs-14 mb-1">
                      {{ file.name }}
                    </h5>
                    <p class="fs-13 text-muted mb-0">
                      <strong> {{ (file.size / 1000000).toFixed(2) }} </strong> MB
                    </p>
                  </div>
                </div>
                <div class="flex-shrink-0 ms-3">
                  <button @click="deleteFile" class="btn btn-sm btn-danger"
                          :disabled="loading_step1">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </li>

          <li>
            <form @submit.prevent="uploadExcel" class="row align-items-end pt-4">

              <div class="col-4 col-lg-4">
                <label class="form-label text-dark">Sheet name</label>
                <select v-model="train_name" class="form-select" :disabled="loading_step1">
                  <option v-for="train in train_name_options" :key="train.name"
                          :value="train.name">
                    {{ train.name }}
                  </option>
                </select>
              </div>

              <div class="col-4 col-lg-4">
                <label class="form-label text-dark">Terminal</label>
                <SelectTerminal @onSelect="terminal = $event"/>
              </div>

              <div class="col-4">

                <button v-if="loading_step1" type="button" class="btn btn-success btn-load">
                                    <span class="d-flex align-items-center">
                                        <span class="flex-grow-1 me-2">
                                        Uploading
                                        </span>
                                        <span class="spinner-grow flex-shrink-0" role="status">
                                        <span class="visually-hidden">Uploading</span>
                                        </span>
                                    </span>
                </button>

                <b-button v-if="canUploadExcelProcess  && !loading_step1"
                          type="submit" variant="success">
                  Upload
                </b-button>

                <b-button v-else-if="!canUploadExcelProcess && !loading_step1 " variant="success"
                          disabled>
                  Upload
                </b-button>
              </div>
            </form>
          </li>

        </ul>
      </b-card-body>
      <b-card-body v-show="step === 'step2'">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr class="align-middle">
              <th>No</th>
              <th style="min-width: 120px">Customer</th>
              <th>Container</th>
              <th>Wagon</th>
              <th>Seal</th>
              <th>Gu</th>
              <th>Bl</th>
              <th>Cont.type</th>
              <th>Shipper</th>
              <th>Consignee</th>
              <th>Cargo</th>
              <th>HS code</th>
              <th>Gross weight</th>
              <th>UNLOADED DATE</th>
            </tr>
            </thead>
            <tbody>

            <tr class="align-middle"
                v-for="data in train_parsed_data" :key="data.index"
                :class="{
                   'bg-soft-danger': data.is_deleted,
                }"
            >
              <th>
                <div class="d-flex align-items-center gap-1">
                  <i @click="data.is_deleted ? data.is_deleted = false : data.is_deleted = true"
                     class="fs-5 c_icon_hoverable align-middle"
                     :class="{
                   'bx bxs-trash-alt text-danger': data.is_deleted !== true,
                   'mdi mdi-undo-variant text-success fw-semibold': data.is_deleted,
                   }"
                  >
                  </i>
                  {{ data.index }}
                </div>
              </th>
              <td style="min-width: 150px">{{ data.customer }}</td>
              <td>{{ data.container }}</td>
              <td>{{ data.wagon }}</td>
              <td>{{ data.seal_number }}</td>
              <td>{{ data.gu }}</td>
              <td>{{ data.bl }}</td>
              <td>{{ data.container_type }}</td>
              <td style="min-width: 150px">
                                <span v-if="data.shipper">
                                    {{ data.shipper.name }}
                                </span>
              </td>
              <td style="min-width: 150px">
                                <span v-if="data.consignee">
                                    {{ data.consignee.name }}
                                </span>
              </td>
              <td>{{ data.cargo }}</td>
              <td>{{ data.hs_code }}</td>
              <td style="min-width: 120px">{{ data.gross_weight }}</td>
              <td style="min-width: 120px"></td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="text-start pt-5">
          <div class="py-3" v-if="train_parsed_data.filter(item=>item.is_deleted).length > 0">
            <b-alert show variant="danger" class="border-0 " role="alert">
              <div class="d-flex align-items-center">
                <i class="bx bxs-error-circle me-2 fs-3"></i>
                <div>
                  <h5 class="text-danger mb-0">
                    <strong>Warning!</strong>
                  </h5>
                  <span class="fs-5 mb-0">
                    <span>There are </span>
                    <span class="fw-bold">{{ train_parsed_data.filter(item => item.is_deleted).length }}</span>
                    <span> items that will be deleted</span>
                  </span>
                </div>
              </div>
            </b-alert>

          </div>
          <b-button v-if="!loading_step2" @click="confirmExcel()" variant="success">Confirm and Submit</b-button>
          <b-button v-else disabled variant="success">Saving..</b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>

</style>