<template>
  <div>
    <Multiselect
        v-model="selectedTerminal"
        :options="terminals"
        :searchable="true"
        :loading="isLoading"
        @search-change="asyncFindTerminal"
        @input="handleChange"
        :object="true"

    />
  </div>
</template>

<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

import axios from "axios";

export default {
  emits: ["onSelect"],
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedTerminal: null,
      terminals: [],
      isLoading: false,
    };
  },
  props: {
    current_terminal: {
      type: Object,
      required: false,
    },

  },
  methods: {
    async asyncFindTerminal(query) {
      if (query.trim().length <= 0) return;
      this.selectedTerminal = null;
      this.isLoading = true;
      let response = await axios.get("/terminal/", {
        params: {
          name: query.trim(),
        },
      });
      if (response.status === 200) {
        let data = response.data.results;
        this.terminals = data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        this.isLoading = false;
      }
    },

    handleChange(val) {
      // returns { value: number, label: string }
      this.$emit("onSelect", val);
    },

    clear() {
      this.selectedTerminal = null;
    },
  },
  mounted() {
    if (this.current_terminal) {
      this.terminals = [{
        value: this.current_terminal.id,
        label: this.current_terminal.name
      }]
      this.selectedTerminal = this.terminals[0]
    }
  },
  watch: {
    current_terminal(value) {
      let validValue = Object.keys(value).length > 0
      if (validValue) {
        this.terminals = [{
          value: value.id,
          label: value.name
        }]
        this.selectedTerminal = this.terminals[0]
      } else {
        this.terminals = []
        this.selectedTerminal = 0
      }
    }
  }
};
</script>

<style scoped></style>
